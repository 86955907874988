import { useSuspenseQuery } from "@tanstack/react-query"

import { useClient } from "api"
import { IAddress } from "models"

const useOrganizationInfo = (id: string) => {
  const { read } = useClient()
  const queryKey = ["practice-name", id]

  const { data: { orgName, orgAddress }, isLoading } = useSuspenseQuery({
    queryKey,
    queryFn: async () => {
      const { name, address } = await read<{ name: string; address: FhirAddress[] }>(`organizations/info/${id}`)
      const orgAddress = fhirAddressToIAddress(address)
        
      return { orgName: name, orgAddress }
    },
  })

  return { orgName, orgAddress, isLoading }
}

const fhirAddressToIAddress = (fhirAddress: FhirAddress[]): IAddress[] => fhirAddress.map((address) => ({
    use: address.use ?? "",
    line1: address.line?.[0] ?? "",
    line2: address.line?.[1] ?? "",
    city: address.city ?? "",
    state: address.state ?? "",
    country: address.country ?? "",
    postalCode: address.postalCode ?? ""
}))

interface FhirAddress {
    city?: string
    country?: string
    line?: string[]
    postalCode?: string
    state?: string
    use?: string
}

export { useOrganizationInfo }
